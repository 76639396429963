import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

const Wrapper = () => import('@/views/layout/Wrapper')
const Home = () => import('@/views/home/index')
const Station = () => import('@/views/product/index')
const Download = () => import('@/views/media/index')
const About = () => import('@/views/about/index')
const Join = () => import('@/views/culture/index')
const Social = () => import('@/views/social/index')
const Recruitment = () => import('@/views/recruitment/index')
const Contact = () => import('@/views/contact/index')
const Medicine = () => import('@/views/herbalMedicine/layout')
const HomeContent = () => import('@/views/herbalMedicine/layout/content.vue')
const SearchHome = () => import('@/views/herbalMedicine/searchHome')
const HerbalMedicine = () => import('@/views/herbalMedicine/herbalMedicine')
const HerbalMedicineInfo = () => import('@/views/herbalMedicine/herbalMedicine/info')
const PatentMedicine = () => import('@/views/herbalMedicine/patentMedicine')
const PatentMedicineInfo = () => import('@/views/herbalMedicine/patentMedicine/info')
const SearchContent = () => import('@/views/herbalMedicine/searchContent')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/',
      name: 'Wrapper',
      redirect: '/home',
      component: Wrapper,
      children: [{
        path: 'home',
        name: 'Home',
        component: Home,
        meta: { title: '首页', keywords: '医学界, 数字营销, 数字化转型, 创新药, 药物品牌, 医疗, 处方药，药企， 医院, 医生, 卫健委, YXJ, Digital Marketing, Multi Channel Marketing, MCM, innovation, medicine, drug, pharma, hospital, doctor, physician, HCO, HCP', description: '首页, 营销专家, 媒体优势, 数字化创新, 医疗品牌, 基医会, 中国医院宣传年会, 服务医者, 改善医疗' }
      }, {
        path: 'product',
        name: 'station',
        component: Station,
        meta: { title: '医生站', keywords: '医生站，App, 应用, 医学, 临床, CME, 用药, 指南, 职考, 会员, 学习, 服务', description: '医生站, 医学资讯,  在线CME, 用药查询，临床指南，医学公式，医学会议，职称考试，会员服务' }
      }, {
        path: 'media',
        name: 'Download',
        component: Download,
        meta: { title: '媒体矩阵', keywords: '医学界, 数字营销, 医院, 医生, 医学, 媒体, 卫健委, 医生站，医学微信号, 医生App, 继续医学教育，媒体矩阵, 疾病, 基层卫生, 书店，癌友', description: '媒体,产品, 肿瘤, 心血管, 内分泌, 风湿, 消化, 皮肤, 妇产, 儿科, 神经, 精神, 血液, 呼吸, 急重症, 感染, 临床药学, 麻醉, 检验, 影像, 护理, 骨科, 外科, 智库, 基层, 书店, 癌友会' }
      }, {
        path: 'about',
        name: 'About',
        component: About,
        meta: { title: '历程', keywords: '医学界, 数字营销, 卫健委, 基医会, 继续医学教育, CME', description: '关于我们, 基医会, 卫生发展研究中心, 基层卫生健康创新发展大会, 医改, 杨浦小巨人, 复星, 高新技术, 上市' }
      }, {
        path: 'culture',
        name: 'Join',
        component: Join,
        meta: { title: '文化', keywords: '医学界, 数字营销, 企业文化, 薪资, 福利, 人才, 招聘, 晋升, 公平, 价值观', description: '团队, 加入我们, 招聘理念,  社招, 校招, 中和位育, 诚朴简雄' }
      }, {
        path: 'social',
        name: 'Social',
        component: Social,
        meta: { title: '社会责任', keywords: '医学界, 企业社会责任, 环境和社会治理, 公益, 慈善, YXJ, ESG, CSR', description: '医学界, 企业社会责任, 环境和社会治理' }
      }, {
        path: 'recruitment',
        name: 'Recruitment',
        component: Recruitment,
        meta: { title: '招聘', keywords: '热招，招募，职位，优秀, 热爱，前程无忧，Boss直聘', description: '招聘职位， 加入我们' }
      }, {
        path: 'contact',
        name: 'Contact',
        component: Contact,
        meta: { title: '联系我们', keywords: '联系，联系我们', description: '电话，邮箱，地址' }
      }]
    },
    {
      path: '/medicine',
      name: 'medicine',
      redirect: '/medicine/searchHome',
      component: Medicine,
      children: [
        {
          path: 'searchHome',
          name: '首页',
          redirect: '/medicine/searchHome/home',
          component: HomeContent,
          children: [
            {
              path: 'home',
              name: '首页',
              component: SearchHome
            },
            {
              path: 'searchContent',
              name: '搜索首页',
              component: SearchContent
            }
          ]
        },
        {
          path: 'herbalMedicine',
          name: '中草药',
          redirect: '/medicine/herbalMedicine/searchContent',
          component: HerbalMedicine,
          children: [
            {
              path: 'searchContent',
              name: '中草药搜索页',
              component: SearchContent
            },
            {
              path: 'productInfo/:drugId',
              name: '中草药',
              component: HerbalMedicineInfo
            }
          ]
        },
        {
          path: 'patentMedicine',
          name: '中成药',
          redirect: '/medicine/patentMedicine/searchContent',
          component: PatentMedicine,
          children: [
            {
              path: 'searchContent',
              name: '中成药搜索页',
              component: SearchContent
            },
            {
              path: 'productInfo/:drugId',
              name: '中成药',
              component: PatentMedicineInfo
            }
          ]
        }
      ]
    },
    // {
    //   path: '*',
    //   redirect: '/home'
    // }
  ]
})

router.beforeEach((to, from, next) => {
  // NProgress.start()
  Vue.nextTick(function(){
    document.title = to.meta.title ? `${to.meta.title} - 医学界` : '医学界'
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords ? `${to.meta.keywords}` : '医学界, 数字营销')
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description ? `${to.meta.description}` : '营销专家, 数字媒体')
  })
  next()
})

// router.afterEach((to, from) => {
  // NProgress.done()
// })

export default router
