// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
/* eslint-disable*/
import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import yxj from './assets/yxj'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/dist/css/swiper.css'
import directive from '@/utils/directives'
import '@/assets/styles/element-variables.scss'

import 'normalize.css/normalize.css'
import animated from 'animate.css'
import './assets/common.css'
import './assets/medic.scss'
import swiper from './components/swiper.vue'
import '@/utils/getOsInfo'
import '@/utils/filters'
// import Vconsole from 'vconsole';

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(animated)
Vue.use(yxj)
Vue.use(VueAwesomeSwiper)
Vue.use(directive)
Vue.component('MySwiper', swiper)

// new Vconsole();
console.log(process.env)

Vue.prototype.showMessage = function (msg, type) {
  if (type) {
    let title = ''
    switch (type) {
      case 'success':
        title = '成功'
        break
      case 'warning':
        title = '警告'
        break
      case 'info':
        title = '提示'
        break
      case 'error':
        title = '错误'
        break
      default:
        title = '提示'
        break
    }
    window.toastName = Vue.prototype.$notify({
      title: title,
      message: msg,
      type: type
    })
    return window.toastName
  } else {
    window.toastName = Vue.prototype.$notify({
      title: '提示',
      message: msg,
      type: 'info'
    })
    return window.toastName
  }
}

// vue单页应用项目加入百度统计代码 方案一【index.html+main.js】
router.beforeEach((to, from, next) => {
  // 统计代码
  if (to.path) {
    _hmt.push(['_trackPageview', to.fullPath])
  }
  next()
})


/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
