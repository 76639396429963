<template>
  <div
    class="swiper-area"
    style="width: 100%;"
  >
    <swiper :options="swiperOption" ref="myswiper" v-if="swiperReset">
      <swiper-slide
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <router-link
          :to="item.to"
          tag="div"
        >
          <img
            class="banner-item"
            :src="$store.getters.mobile ? item.mobileImg : item.img"
          />
          <p class="sinfo" v-if="item.info">{{item.info}}</p>
        </router-link>
      </swiper-slide>
      <div
        class="swiper-pagination"
        v-show="pagination"
        slot="pagination"
      ></div>
    </swiper>
    <div
      class="swiper-button-next swiper-next swiper-button"
      v-show="!$store.getters.mobile && bannerList.length > 1"
    ></div>
    <div
      class="swiper-button-prev swiper-prev swiper-button"
      v-show="!$store.getters.mobile && bannerList.length > 1"
    ></div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  props: {
    bannerList: {
      type: Array
    },
    pagination: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      swiperReset: true,
      swiperOption: {
        autoHeight: true,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 5000
        },
        speed: 500,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
          // bulletActiveClass: 'bullet-active'
        },
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        }
      }
    }
  },
  computed: {
    ...mapState({
      mobile: state => state.user.mobile
    })
  },
  watch: {
    mobile() {
      // 修复轮播图切换图片loop下生成的solid不更新问题
      this.swiperReset = false;
      this.$nextTick(() => {
        this.swiperReset= true
      })
    }
  }
}
</script>

<style>
</style>