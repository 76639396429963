
import store from '@/store'

const getOsInfo = () => {
  let navigator = window.navigator;
  let userAgent = navigator.userAgent.toLowerCase()
  let version = ''
  let browser = ''
  if(userAgent.indexOf("win") > -1) {
    if(userAgent.indexOf("windows nt 5.0") > -1 || userAgent.indexOf("Windows 2000") > -1) {
      version = "Windows 2000";
    } else if(userAgent.indexOf("windows nt 5.1") > -1 || userAgent.indexOf("Windows XP") > -1) {
      version = "Windows XP";
    } else if(userAgent.indexOf("windows nt 5.2") > -1 || userAgent.indexOf("Windows 2003") > -1) {
      version = "Windows 2003";
    } else if(userAgent.indexOf("windows nt 6.0") > -1 || userAgent.indexOf("Windows Vista") > -1) {
      version = "Windows Vista";
    } else if(userAgent.indexOf("windows nt 6.1") > -1 || userAgent.indexOf("windows 7") > -1) {
      version = "Windows 7";
    } else if(userAgent.indexOf("windows nt 6.2") > -1 || userAgent.indexOf("windows 8") > -1) {
      version = "Windows 8";
    } else if(userAgent.indexOf("windows nt 6.3") > -1) {
      version = "Windows 8.1";
    } else if(userAgent.indexOf("windows nt 6.4") > -1 || userAgent.indexOf("windows nt 10") > -1) {
      version = "Windows 10";
    } else {
      version = "Unknown";
    }
  } else if(userAgent.indexOf("iphone") > -1) {
    version = "Iphone";
  } else if(userAgent.indexOf("mac") > -1) {
    version = "Mac";
  } else if(userAgent.indexOf("x11") > -1 || userAgent.indexOf("unix") > -1 || userAgent.indexOf("sunname") > -1 || userAgent.indexOf("bsd") > -1) {
    version = "Unix";
  } else if(userAgent.indexOf("linux") > -1) {
    if(userAgent.indexOf("android") > -1) {
      version = "Android"
    } else {
      version = "Linux";
    }
  } else {
    version = "Unknown";
  }
  store.commit("GET_NATIVEINFO",{
    version,
    browser
  })
  console.log("当前设备===", {
    version,
    browser
  })
  return {
    version,
    browser
  };
}

export default getOsInfo;